<template>
    <div class="body flex flex-column justify-content-between" style="margin: 0 auto; height: 100vh">
        <div class="flex justify-content-center">
            <div class="surface-card shadow-2 w-full">
                <div class="text-center">
                    <img src="./assets/head.png" alt="Head" style="width: 100%; min-width: 50%" />
                </div>
            </div>
        </div>

        <div class="flex justify-content-center h-full">
            <div
                class="surface-card shadow-2 w-full"
                style="
                    background-image: url('/demo/images/checkin/background.png');
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                "
            >
                <div>
                    <div
                        v-if="!mostrarFormulario"
                        class="p-4 w-full md:flex-row justify-content-center flex flex-column-reverse align-items-center md:align-items-end h-full"
                        style="margin-top: 40%"
                    >
                        <div class="flex flex-column gap-3 xl:w-4 lg:w-5 md:w-5 w-full" style="transform: scale(2.1)">
                            <div>
                                <h4><strong>Digite aqui seu CPF</strong></h4>
                            </div>
                            <div class="flex align-items-center" style="position: relative">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 10rem; height: 4rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span
                                    style="
                                        position: absolute;
                                        top: 2.1rem;
                                        left: 4.4rem;
                                        transform: translate(-50%, -50%);
                                        color: #fff;
                                        font-weight: 900;
                                        font-size: large;
                                    "
                                    >CPF</span
                                >
                                <InputMask
                                    ref="cpfInput"
                                    v-model="cpf"
                                    mask="999.999.999-99"
                                    id="cpf"
                                    type="text"
                                    :class="'w-full' + (v$.cpf.$error ? ' p-invalid' : '')"
                                    style="border-radius: 0 40px 40px 0; font-size: large"
                                    :unmask="true"
                                    :autoClear="false"
                                    @input="v$.cpf.$touch()"
                                    @keydown.enter="buscarCpf()"
                                    @click="setFocusAndCursorOnCpfInput()"
                                    inputmode="numeric"
                                />
                            </div>
                            <span v-if="v$.cpf.required.$invalid && v$.cpf.$error" class="p-error ml-3">Campo obrigatório!</span>
                            <span v-if="v$.cpf.cpfValido.$invalid" class="p-error ml-3">CPF inválido!</span>
                            <div class="flex justify-content-center">
                                <Button
                                    label="Inscreva-se"
                                    class="mt-4 w-9"
                                    style="background-color: #fd6c65; border-color: #fd6c65"
                                    @click.stop="buscarCpf()"
                                    :disabled="v$.cpf.cpfValido.$invalid || cpf == ''"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="mostrarFormulario"
                        class="p-4 w-9 md:flex-row justify-content-between flex flex-column-reverse align-items-center md:align-items-end"
                        style="margin: 0 auto"
                    >
                        <div class="flex flex-column gap-3 w-full" style="margin: 0 auto">
                            <div class="flex align-items-center" style="position: relative">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                    >CPF</span
                                >
                                <InputMask
                                    v-model="cpf"
                                    mask="999.999.999-99"
                                    id="cpf"
                                    type="text"
                                    :class="'w-full' + (v$.cpf.$error ? ' p-invalid' : '') + (inscricaoEncontrada ? ' text-disabled' : '')"
                                    :disabled="inscricaoEncontrada"
                                    style="border-radius: 0 40px 40px 0"
                                    :unmask="true"
                                    :autoClear="false"
                                    @input="v$.cpf.$touch()"
                                />
                            </div>
                            <span v-if="v$.cpf.required.$invalid && v$.cpf.$error" class="p-error ml-3">Campo obrigatório!</span>
                            <span v-if="v$.cpf.cpfValido.$invalid" class="p-error ml-3">CPF inválido!</span>

                            <div class="flex align-items-center mt-3" style="position: relative">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                    >Nome</span
                                >
                                <InputText
                                    v-model="nome"
                                    id="nome"
                                    type="text"
                                    :class="'w-full' + (v$.nome.$error ? ' p-invalid' : '') + (inscricaoEncontrada ? ' text-disabled' : '')"
                                    :disabled="inscricaoEncontrada"
                                    style="border-radius: 0 40px 40px 0"
                                    @input="v$.nome.$touch()"
                                />
                            </div>
                            <span v-if="v$.nome.$error" class="p-error ml-3">Campo obrigatório!</span>

                            <div class="flex align-items-center mt-3" style="position: relative">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                    >Nome Crachá</span
                                >
                                <InputText
                                    v-model="nomeSocial"
                                    id="nomeSocial"
                                    type="text"
                                    :class="'w-full' + (inscricaoEncontrada ? ' text-disabled' : '')"
                                    style="border-radius: 0 40px 40px 0"
                                    :disabled="inscricaoEncontrada"
                                />
                            </div>

                            <div class="flex align-items-center mt-3" style="position: relative">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                    >E-mail</span
                                >
                                <InputText
                                    v-model="email"
                                    id="email"
                                    type="email"
                                    :class="'w-full' + (v$.email.$error ? ' p-invalid' : '') + (inscricaoEncontrada ? ' text-disabled' : '')"
                                    style="border-radius: 0 40px 40px 0"
                                    @input="v$.email.$touch()"
                                    :disabled="inscricaoEncontrada"
                                />
                            </div>
                            <span v-if="v$.email.$error" class="p-error ml-3">Campo obrigatório!</span>

                            <div class="flex align-items-center mt-3" style="position: relative">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                    >Celular</span
                                >
                                <InputMask
                                    v-model="telefone"
                                    mask="(99) 9 9999-9999"
                                    id="telefone"
                                    type="text"
                                    :class="'w-full' + (v$.telefone.$error ? ' p-invalid' : '') + (inscricaoEncontrada ? ' text-disabled' : '')"
                                    :unmask="true"
                                    :autoClear="false"
                                    style="border-radius: 0 40px 40px 0"
                                    @input="v$.telefone.$touch()"
                                    :disabled="inscricaoEncontrada"
                                />
                            </div>
                            <span v-if="v$.telefone.$error" class="p-error ml-3">Campo obrigatório!</span>

                            <div class="flex align-items-center mt-3" style="position: relative">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                    >CEP</span
                                >
                                <InputMask
                                    v-model="cep"
                                    mask="99999-999"
                                    id="cep"
                                    type="text"
                                    :class="'w-full' + (inscricaoEncontrada ? ' text-disabled' : '')"
                                    style="border-radius: 0 40px 40px 0"
                                    :unmask="true"
                                    :autoClear="false"
                                    :disabled="inscricaoEncontrada"
                                />
                            </div>

                            <div v-if="mostrarFormulario" class="flex flex-column justify-content-start mt-3">
                                <div class="w-full block text-900 font-medium mb-2 mt-3" style="font-weight: 900; font-size: medium">
                                    <b>Qual atividade empresarial você desenvolve?</b>
                                </div>
                                <div class="flex flex-column gap-3 mt-3 w-full">
                                    <div v-for="tipo of tiposInscricao" :key="tipo.value" class="flex align-items-center" style="font-size: medium">
                                        <RadioButton
                                            :disabled="inscricaoEncontrada"
                                            v-model="tipoInscricao"
                                            :inputId="tipo.label"
                                            name="tipoInscricaoRadio"
                                            :value="tipo.value"
                                            class="mt-1"
                                        />
                                        <label class="ml-2" :for="tipo.value">{{ tipo.label }}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="flex align-items-center mt-3" style="position: relative" v-if="tipoInscricao == 1 || tipoInscricao == 2">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                    >CNPJ</span
                                >
                                <InputMask
                                    v-model="cnpj"
                                    mask="99.999.999/9999-99"
                                    id="cnpj"
                                    type="text"
                                    :class="'w-full' + (v$.cnpj.$error ? ' p-invalid' : '') + (inscricaoEncontrada ? ' text-disabled' : '')"
                                    style="border-radius: 0 40px 40px 0"
                                    :unmask="true"
                                    :autoClear="false"
                                    @input="v$.cnpj.$touch()"
                                    :disabled="inscricaoEncontrada"
                                />
                            </div>
                            <span v-if="v$.cnpj.cnpjValido.$invalid" class="p-error ml-3">CNPJ inválido!</span>

                            <div v-if="tipoInscricao == 2">
                                <div class="flex align-items-center mt-4" style="position: relative">
                                    <img
                                        src="./assets/label.png"
                                        alt="Label"
                                        style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                    />
                                    <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                        >DAP</span
                                    >
                                    <InputMask
                                        v-model="dap"
                                        mask="*************************"
                                        id="dap"
                                        type="text"
                                        :class="'w-full' + (inscricaoEncontrada ? ' text-disabled' : '')"
                                        style="border-radius: 0 40px 40px 0"
                                        :unmask="true"
                                        :autoClear="false"
                                        :disabled="inscricaoEncontrada"
                                    />
                                </div>
                                <div class="flex align-items-center mt-3" style="position: relative">
                                    <img
                                        src="./assets/label.png"
                                        alt="Label"
                                        style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                    />
                                    <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                        >CAEPF</span
                                    >
                                    <InputMask
                                        v-model="caepf"
                                        mask="***.***.***/***-**"
                                        id="caepf"
                                        type="text"
                                        :class="'w-full' + (inscricaoEncontrada ? ' text-disabled' : '')"
                                        style="border-radius: 0 40px 40px 0"
                                        :unmask="true"
                                        :autoClear="false"
                                        :disabled="inscricaoEncontrada"
                                    />
                                </div>
                                <div class="flex align-items-center mt-3" style="position: relative">
                                    <img
                                        src="./assets/label.png"
                                        alt="Label"
                                        style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                    />
                                    <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                        >CAF</span
                                    >
                                    <InputMask
                                        v-model="caf"
                                        mask="********.**.************"
                                        id="caf"
                                        type="text"
                                        :class="'w-full' + (inscricaoEncontrada ? ' text-disabled' : '')"
                                        style="border-radius: 0 40px 40px 0"
                                        :unmask="true"
                                        :autoClear="false"
                                        :disabled="inscricaoEncontrada"
                                    />
                                </div>
                                <div class="flex align-items-center mt-3" style="position: relative">
                                    <img
                                        src="./assets/label.png"
                                        alt="Label"
                                        style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                    />
                                    <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                        >NIRF</span
                                    >
                                    <InputMask
                                        v-model="nirf"
                                        mask="*.***.***-*"
                                        id="nirf"
                                        type="text"
                                        :class="'w-full' + (inscricaoEncontrada ? ' text-disabled' : '')"
                                        style="border-radius: 0 40px 40px 0"
                                        :unmask="true"
                                        :autoClear="false"
                                        :disabled="inscricaoEncontrada"
                                    />
                                </div>

                                <div class="flex align-items-center mt-3" style="position: relative">
                                    <img
                                        src="./assets/label.png"
                                        alt="Label"
                                        style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                    />
                                    <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                        >RPG</span
                                    >
                                    <InputMask
                                        v-model="rpg"
                                        mask="***************"
                                        id="rpg"
                                        type="text"
                                        :class="'w-full' + (inscricaoEncontrada ? ' text-disabled' : '')"
                                        style="border-radius: 0 40px 40px 0"
                                        :unmask="true"
                                        :autoClear="false"
                                        :disabled="inscricaoEncontrada"
                                    />
                                </div>
                            </div>

                            <div class="flex align-items-center mt-4" style="position: relative" v-if="tipoInscricao == 3">
                                <img
                                    src="./assets/label.png"
                                    alt="Label"
                                    style="width: 7.8rem; height: 3.3rem; position: relative; left: 0.4rem; bottom: 0.2rem"
                                />
                                <span style="position: absolute; top: 1.8rem; left: 3.8rem; transform: translate(-50%, -50%); color: #fff; font-weight: 900"
                                    >SICAB</span
                                >
                                <InputMask
                                    v-model="sicab"
                                    mask="**.****.*******.**"
                                    id="sicab"
                                    type="text"
                                    :class="'w-full' + (inscricaoEncontrada ? ' text-disabled' : '')"
                                    style="border-radius: 0 40px 40px 0"
                                    :unmask="true"
                                    :autoClear="false"
                                    :disabled="inscricaoEncontrada"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="mostrarFormulario"
                        class="p-4 w-9 flex flex-column md:flex md:justify-content-between md:flex-column align-items-center"
                        style="margin: 0 auto"
                    >
                        <div for="descricao" class="w-full block text-900 font-medium" style="font-weight: 900; font-size: medium">
                            <b>
                                A feira apresentará uma série de conteúdos e oportunidades de negócio. <br />
                                Nos ajude a entender seus interesses na feira do empreendedor:</b
                            >
                        </div>

                        <div for="descricao" class="w-full block text-900 text-blue-600 font-medium mt-3" style="font-weight: 900; font-size: small">
                            No mínimo 1, no máximo 3.
                        </div>
                        <div class="flex flex-column gap-3 mt-3 w-full">
                            <div v-for="tema of temas" :key="tema.temaId" class="flex align-items-center" style="font-size: medium">
                                <Checkbox
                                    :disabled="desabilitarTema(tema) || inscricaoEncontrada"
                                    v-model="temasSelecionados"
                                    :inputId="tema.temaId"
                                    name="category"
                                    :value="tema"
                                    class="mt-1"
                                />
                                <label class="ml-2" :for="tema.temaId">{{ tema.descricao }}</label>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="mostrarFormulario"
                        class="p-4 w-9 md:w-full flex flex-column md:flex md:justify-content-between md:flex-column align-items-center"
                        style="margin: 0 auto"
                    >
                        <div for="descricao" class="w-full block text-900 font-medium mb-2 mt-3" style="font-weight: 900; font-size: medium">
                            <b> Qual setor econômico você faz parte ou tem interesse?</b>
                        </div>
                        <div class="flex flex-column gap-3 mt-3 w-full">
                            <div v-for="setor of setoresEconomicos" :key="setor.setorEconomicoId" class="flex align-items-center" style="font-size: medium">
                                <RadioButton
                                    :disabled="inscricaoEncontrada"
                                    v-model="setoresEconomicosSelecionados"
                                    :inputId="setor.setorEconomicoId"
                                    name="category"
                                    :value="setor"
                                    class="mt-1"
                                />
                                <label class="ml-2" :for="setor.setorEconomicoId">{{ setor.descricao }}</label>
                            </div>
                            <span v-if="v$.setoresEconomicosSelecionados.$error" class="p-error ml-3">Campo obrigatório!</span>
                        </div>
                    </div>

                    <div v-if="mostrarFormulario" class="p-4 w-9 md:flex md:align-items-center md:flex-column" style="margin: 0 auto">
                        <label for="descricao" class="block text-900 font-medium mb-2" style="font-weight: 900; font-size: medium"
                            ><b>Conte-nos um pouco mais detalhadamente sobre o que você deseja encontrar na feira do empreendedor:</b></label
                        >
                        <Textarea
                            :disabled="inscricaoEncontrada"
                            v-model="descricao"
                            id="descricao"
                            autoResize
                            :class="'w-full mb-3' + (inscricaoEncontrada ? ' text-disabled' : '')"
                            rows="5"
                            cols="30"
                            style="border-radius: 30px"
                        />
                    </div>

                    <div v-if="mostrarFormulario" class="p-4 w-9 flex align-items-center" style="font-size: small; text-align: justify; margin: 0 auto">
                        <span class="ml-2"
                            >Utilizaremos os dados coletados neste formulário para viabilizar a sua participação na Feira do Empreendedor e para que possamos
                            entrar em contato com você para oferecer outros produtos, serviços e soluções que entendamos que também possam ser do seu interesse.
                            Além disso, poderemos compartilhar esses dados com os expositores cujos estandes você visitar, para que eles entrem em contato e
                            enviem conteúdos relacionados ao tema do evento. Você poderá, a qualquer tempo, solicitar a eles para deixar de receber comunicações
                            promocionais. Para obter mais informações acerca de como nós, do SEBRAE/PE, tratamos seus dados, como outras eventuais finalidades
                            para as quais os utilizamos e as medidas de segurança que adotamos para protegê-los, acesse aqui a nossa Política de
                            Privacidade.</span
                        >
                    </div>

                    <div v-if="mostrarFormulario" class="flex flex-column justify-content-center align-items-center w-full" style="height: 8%">
                        <Button
                            v-if="!inscricaoEncontrada"
                            label="Enviar"
                            class="w-full lg:w-5 mb-3"
                            style="background-color: #fd6c65; border-color: #fd6c65"
                            :disabled="v$.$invalid"
                            @click.stop="enviar()"
                        />
                        <Button
                            v-if="!inscricaoEncontrada"
                            label="Voltar"
                            class="w-full lg:w-5 mb-3"
                            style="background-color: #0f99f9; border-color: #0f99f9"
                            @click.stop="voltar()"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <img src="./assets/rodape.png" alt="Rodape" style="width: 100%; min-width: 50%" />
        </div>
    </div>

    <div class="p-dialog-mask p-component-overlay p-component-overlay-enter" v-if="visible">
        <div class="p-dialog p-component" role="dialog" aria-labelledby="pv_id_2_header" pv_id_3="" style="width: 30rem; z-index: 1101">
            <div class="p-dialog-header" style="display: flex; justify-content: end">
                <Button
                    icon="pi pi-times"
                    severity="danger"
                    outlined
                    aria-label="Cancel"
                    @click="fecharModal()"
                    style="background: #fff; background-color: #fd6c65"
                />
            </div>
            <div class="p-dialog-content"><img src="./assets/confirmacao.png" alt="" style="width: 100%; height: 100%" /></div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import service from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    data() {
        return {
            nome: '',
            email: '',
            nomeSocial: '',
            cpf: '',
            cnpj: '',
            telefone: '',
            descricao: '',
            cep: '',

            temasSelecionados: [],
            temas: [],
            setoresEconomicos: [],
            setoresEconomicosSelecionados: [],

            visible: false,
            mostrarFormulario: false,
            inscricaoEncontrada: false,

            tipoInscricao: -1,
            tiposInscricao: [
                { label: 'Sou empresário', value: 1 },
                { label: 'Sou produtor rural', value: 2 },
                { label: 'Sou artesão', value: 3 },
                { label: 'Não desenvolvo nenhuma atividade empresarial', value: 0 },
            ],

            dap: '',
            caepf: '',
            caf: '',
            nirf: '',
            rpg: '',
            sicab: '',
        };
    },

    validations() {
        return {
            nome: { required },
            email: { required, email },
            cpf: {
                required,
                cpfValido: (value) => {
                    if (value === '' || value === null || value === undefined) {
                        return true;
                    }
                    return this.$validarCPF(value);
                },
            },
            telefone: { required },
            cnpj: {
                cnpjValido: () => {
                    if (this.cnpj === '' || this.cnpj === null || this.cnpj === undefined) {
                        return true;
                    }
                    return this.$validarCNPJ(this.cnpj);
                },
            },
            temasSelecionados: {
                temasValidos: () => {
                    if (this.temasSelecionados.length < 1) {
                        return false;
                    }
                    return true;
                },
            },
            setoresEconomicosSelecionados: { required },
            tipoInscricao: {
                tipoInscricaoValido: () => {
                    return this.tipoInscricao > -1 ? true : false;
                },
            },
        };
    },

    mounted() {
        this.obterTemas();
        this.obterSetoresEconomicos();
        window.fbq('track', 'PageView');
    },

    created() {
        window.fbq('init', '452655677138694');
    },

    methods: {
        setFocusAndCursorOnCpfInput() {
            this.$refs.cpfInput.$el.focus();
            this.$refs.cpfInput.$el.setSelectionRange(0, 0);
        },

        abrirModal() {
            this.visible = true;

            setTimeout(() => {
                this.visible = false;
            }, 4000);
        },

        fecharModal() {
            this.visible = false;
        },

        buscarCpf() {
            this.$store.dispatch('addRequest');
            service
                .buscarCpf(this.cpf, 'TOTEM')
                .then((response) => {
                    if (response?.success) {
                        this.nome = response.data.nome;
                        this.email = response.data.email;
                        this.nomeSocial = response.data.nomeSocial;
                        this.telefone = response.data.telefone;
                        this.baixarEtiqueta();
                    } else {
                        this.mostrarFormulario = true;
                        this.inscricaoEncontrada = false;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        baixarEtiqueta() {
            this.$store.dispatch('addRequest');
            let inscricao = {
                cpf: this.cpf,
                nome: this.nomeSocial ? this.nomeSocial : this.nome,
                email: this.email,
                telefone: this.telefone,
            };

            service
                .emitirQrCode(inscricao)
                .then((response) => {
                    if (response?.success) {
                        this.printPDF(response.data, `${this.nome}-Qrcode.pdf`, 'application/pdf');
                        this.limparForm();
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                    this.router.push('/checkins/feiraempreendedor/totem');
                });
        },

        printPDF(data, filename, mime) {
            var blob = new Blob([data], { type: mime || 'application/octet-stream' });
            var blobURL = window.URL.createObjectURL(blob);

            var printIframe = document.createElement('iframe');
            document.body.appendChild(printIframe);

            printIframe.style.position = 'absolute';
            printIframe.style.top = '0';
            printIframe.style.left = '-1000px';

            printIframe.onload = function () {
                setTimeout(function () {
                    printIframe.focus();
                    printIframe.contentWindow.print();
                }, 100);
            };

            printIframe.src = blobURL;
        },

        enviar() {
            this.$store.dispatch('addRequest');
            let inscricao = {
                fonteOrigem: 'TOTEM',
                nome: this.nome,
                nomeSocial: this.nomeSocial,
                email: this.email,
                cpf: this.cpf,
                cnpj: this.cnpj,
                cep: this.cep,
                telefone: this.telefone,
                desejaEncontrar: this.descricao,
                tipo: this.tipoInscricao,
                dap: this.dap,
                caepf: this.caepf,
                caf: this.caf,
                nirf: this.nirf,
                rpg: this.rpg,
                sicab: this.sicab,
                temas: this.temasSelecionados,
                setoresEconomicos: [this.setoresEconomicosSelecionados],
            };

            service
                .realizarInscricao(inscricao)
                .then((response) => {
                    if (response?.success) {
                        this.abrirModal();
                        this.baixarEtiqueta();
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Erro',
                            detail: 'Erro ao realizar inscrição',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        desabilitarTema(tema) {
            return this.temasSelecionados.length >= 3 && !this.temasSelecionados.includes(tema);
        },

        obterTemas() {
            service.obterTemas().then((response) => {
                if (response?.success) {
                    this.temas = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Erro ao obter temas',
                        life: 3000,
                    });
                }
            });
        },

        obterSetoresEconomicos() {
            service.obterSetoresEconomicos().then((response) => {
                if (response?.success) {
                    this.setoresEconomicos = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Erro ao obter setores econômicos',
                        life: 3000,
                    });
                }
            });
        },

        voltar() {
            this.limparForm();
            this.router.push('/checkins/feiraempreendedor/totem');
        },

        limparForm() {
            this.nome = '';
            this.nomeSocial = '';
            this.email = '';
            this.cpf = '';
            this.cnpj = '';
            this.cep = '';
            this.telefone = '';
            this.descricao = '';
            this.temasSelecionados = [];
            this.setoresEconomicosSelecionados = [];
            this.mostrarFormulario = false;
            this.inscricaoEncontrada = false;
            this.dap = '';
            this.caepf = '';
            this.caf = '';
            this.nirf = '';
            this.rpg = '';
            this.sicab = '';
            this.tipoInscricao = -1;
            this.v$.$reset();
        },
    },

    watch: {
        tipoInscricao() {
            this.cnpj = '';
            this.dap = '';
            this.caepf = '';
            this.caf = '';
            this.nirf = '';
            this.rpg = '';
            this.sicab = '';
        },
    },
};
</script>

<style scoped lang="scss">
@font-face {
    font-family: 'Bahnschrift';
    font-style: normal;
    font-weight: 400;
    src: local('Bahnschrift'), url(https://fonts.gstatic.com/s/bahnschrift/v5/vW2AZWuCKbNKe0FvOXmEBw.woff2) format('woff2');
}

.body {
    background-color: #ececed;
    font-family: 'Bahnschrift', sans-serif;
}

::v-deep(.p-inputtext:enabled:focus) {
    box-shadow: none;
}

::v-deep(.p-inputtext) {
    padding-left: 1.1rem;
    border: 1.5px solid #0f99f9;
}

::v-deep(.p-dialog) {
    background: none !important;
    box-shadow: none;
}

::v-deep(.p-dialog-content) {
    background: none !important;
    box-shadow: none;
}

::v-deep(.p-dialog-header) {
    background: none !important;
    box-shadow: none;
}

::v-deep(.p-inputtext:disabled, .p-checkbox:disabled) {
    opacity: 1 !important;
}

.text-disabled {
    color: black;
    font-size: small;
    font-weight: 700;
}
</style>
