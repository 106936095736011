import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKINS_PORT}${process.env.VUE_APP_API_PATH}/checkins`;
const urlBase = `${api}/inscricoes`;

export default {
    realizarInscricao(inscricao) {
        return axiosJwt.post(`${urlBase}`, inscricao);
    },

    atualizarInscricao(inscricao) {
        return axiosJwt.put(`${urlBase}`, inscricao);
    },

    obterTemas() {
        return axiosJwt.get(`${urlBase}/temas`);
    },

    obterSetoresEconomicos() {
        return axiosJwt.get(`${urlBase}/setoreseconomicos`);
    },

    buscarCpf(cpf, fonte) {
        return axiosJwt.get(`${urlBase}/${cpf}/${fonte}`);
    },

    emitirQrCode(dados) {
        return axiosJwt.post(`${urlBase}/qrcode`, dados, {
            responseType: 'blob',
        });
    },
};
